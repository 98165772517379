@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue" "Monserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.buttonShadow {
  box-shadow: 2px 4px 5px 0px #666;
}

.carouselComponent {
  display: inline-flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

model-viewer {
  width: 100%;
}

.model-viewer-gallery {
  height: 100%;
}

.padding-bottom {
  padding-bottom: 24vh;
}

.img-home {
  height: 24vh;
}

.test {
  height: 92vh;
}

.img-size {
  width: 100%;
  height: 32vh;
  object-fit: cover;
  z-index: 1;
  position: absolute;
}

.img-loading {
  max-height: 32vh;
  width: 100%;
  height: 32vh;
  object-fit: cover;
  position: static;
  background-color: #e5ecff;
}

details {
  user-select: none;
}

details > summary span.icon {
  margin-left: auto;
}

details[open] summary span.icon {
  transform: rotate(180deg);
}

summary {
  display: flex;
  cursor: pointer;
}

summary::-webkit-details-marker {
  display: none;
}

@media only screen and (min-width: 601px) {
  .img-size {
    max-height: 44vh;
    height: 44vh;
    object-fit: cover;
  }
  .img-loading {
    max-height: 44vh;
    height: 44vh;
    object-fit: cover;
    z-index: -1;
    background-color: #e5ecff;
  }
}

@media only screen and (min-width: 601px) {
  .gallery-img-size {
    max-height: 90vh;
    height: 85vh;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 20px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #e5e6e9;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #aaacb2;
    border-radius: 100vh;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #aaacb2;
  }
}

.map-model-viewer {
  height: 100vh;
}

.annotation {
  color: white;
  position: absolute;
  font-size: 0.7rem;
}

.annotation .circle {
  background-color: rgb(220, 39, 39);
  padding: 10px;
  border-radius: 20px;
  max-width: 1rem;
  height: 1rem;
}

.map-model-viewer-annotation {
  --min-hotspot-opacity: 0;
  border: none;
  box-sizing: border-box;
  pointer-events: none;
}

html,
body {
  height: 100%;
}
.wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.h_iframe {
  position: relative;
  padding-top: 105%;
}
.h_iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.h_frame_mobile {
  position: initial;
  padding-top: initial;
}

#ar {
  position: absolute;
  top: 8vh;
  right: 4%;
}

.anim {
  height: 100vh;
}

/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease-in-out 2000ms;
  height: 100%;
}

.slide {
  display: inline-block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.panorama-360-view {
  height: 100vh;
}

#mobile-panorama-360-view {
  width: 100vw;
}

#ar-button {
  display: block;
  position: absolute;
  top: 5vh;
  right: 1vh;
}
